import { AuthService } from './../auth/auth.service';
import { Component, OnInit, OnDestroy, ComponentFactoryResolver, } from '@angular/core';
import { DatePipe, PlatformLocation } from '@angular/common';
import {
  Receipt, Merchant, Label, Currency, Period, MyCurrency, MerchantForFilter, Total,
} from '@app/shared/yubilly-data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalConfigComponent } from '@app/components/modal-config/modal-config.component';
import {
  faEnvelope, faReceipt, faRedo, faTemperatureLow, faPlus, faMinus, faTimesCircle, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl, Validators, FormBuilder, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { YubillyService } from '@app/shared/yubilly.service';
// import { translate } from '@angular/localize/src/utils';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Console } from 'console';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.css'],
})
export class ReceiptListComponent implements OnInit, OnDestroy {
  isContainerListActive = true;
  isModalSelectionActive = false;
  location: PlatformLocation;
  filtersShow = '';
  //
  pageSize = 25;
  pageNumber = 1;
  lastPage = false;
  //
  receipts: Receipt[] = [];
  merchants: Merchant[] = [];
  merchantsForFilter: MerchantForFilter[] = [];
  labels: Label[] = [{
    labelId: 0,
    labelName: 'allLabels',
    labelColor: '',
    inUse: false
  }];
  myCurrencyCodes: MyCurrency[] = [];
  periods: Period[] = [];
  totals: Total[] = [];
  //
  fileName = '';
  //
  selectedPeriod: Period = {
    periodId: 0,
    period: ''

  };
  selectedLabel: Label = {
    labelId: 0,
    labelName: 'all',
    labelColor: '',
    inUse: false
  };
  selectedMerchant: MerchantForFilter = {
    merchantId: 0,
    merchantName: ''
  };
  selectedCurrency: MyCurrency = {
    currencyCode: 'all'
  };
  //
  destroy$: Subject<boolean> = new Subject<boolean>();
  downloadEnabled = true;
  iconEmail = faEnvelope;
  iconReceipt = faReceipt;
  iconRefresh = faRedo;
  iconResetFilters = faTimesCircle;
  iconSpinner = faSpinner;
  iconPlus = faPlus;
  iconMinus = faMinus;
  //
  merchantId: number;
  merchantName: string;
  receiptDate: Date;
  receiptTotal: number;
  receiptToEdit: Receipt;
  receiptIndexToEdit: number;
  longJobFilters = false;
  longJobRefresh = false;
  downloadingLookupList = false;
  merchantNameEdit = '';
  downloadingItems = false;
  //
  datePipe: DatePipe = new DatePipe('en-US');
  receiptEdit: FormGroup;
  // decimal validators: ^\d+(\.\d{0,2})?$

  private amountValidators = [
    Validators.maxLength(12),
    Validators.minLength(1),
    Validators.pattern('^d+(.d{0,2})?$'),
  ];

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    location: PlatformLocation,
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent,
    private fb: FormBuilder
  ) {
    this.location = location;
  }

  ngOnInit(): void {
    if (localStorage.getItem('yubilly.filtersShow') !== null) {
      // console.log('this.filtersShow:' + this.filtersShow);
      this.filtersShow = localStorage.getItem('yubilly.filtersShow');
    } else {
      localStorage.setItem('yubilly.filtersShow', '');
    }

    this.location.onPopState(() => {
      if (this.isModalSelectionActive) {
        this.modalConfig.closeAll('back');
        this.isModalSelectionActive = false;
      } else if (!this.isContainerListActive) {
        this.isContainerListActive = true;
      }
    });
    this.receiptEdit = this.fb.group({
      merchantName: [''],
      receiptDate: [''],
      receiptTotal: [''],
      currency: [''],
      labelName: [''],
    });
    this.receiptEdit
      .get('receiptTotal')
      .setValidators(this.amountValidators.concat(Validators.required));

    this.pageNumber = 1;
    this.lastPage = false;

    this.getReceiptList();
  }

  isUserBasicMember(): boolean {
    return this.authService.isUserBasicMember();
  }
  isUserPremiumMember(): boolean {
    return this.authService.isUserPremiumMember();
  }

  showHideFilters(): void {
    // console.log('this.filtersShow1:'+this.filtersShow);
    if (this.filtersShow === '') {
      this.filtersShow = 'show';
    } else {
      this.filtersShow = '';
    }
    localStorage.setItem('yubilly.filtersShow', this.filtersShow);
    // console.log('this.filtersShow2:'+this.filtersShow);
  }

  resetFilters(): void {
    this.selectedMerchant = this.merchantsForFilter[0];
    this.selectedLabel = this.labels[0];
    this.selectedPeriod = this.periods[0];
    this.selectedCurrency = this.myCurrencyCodes[0];
    this.refreshReceipts(true);
  }

  getReceiptList(): void {
    this.longJobRefresh = true;
    this.yubillyService
      .getReceiptsWithFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          // console.log(data[0].merchants);
          // console.log(data[0].labels);
          this.receipts = data[0].receipts;
          //
          this.merchantsForFilter = data[0].merchants;
          this.merchantsForFilter.unshift({
            merchantId: 0,
            merchantName: "allMerchants"
          });
          this.labels = data[0].labels;
          this.labels.unshift({
            labelId: 0,
            labelName: "allLabels",
            labelColor: "",
            inUse: false
          });
          this.periods = data[0].periods;
          this.periods.unshift({
            periodId: 0,
            period: "allPeriods"
          });
          this.myCurrencyCodes = data[0].currencies;
          this.myCurrencyCodes.unshift({
            currencyCode: "allCurrencies"
          });
          //
          this.totals = data[0].totals;
          this.longJobRefresh = false;
          this.selectedMerchant = this.merchantsForFilter[0];
          this.selectedLabel = this.labels[0];
          this.selectedPeriod = this.periods[0];
          this.selectedCurrency = this.myCurrencyCodes[0];
        },
        (error) => {
          this.longJobRefresh = false;
        }
      );
  }

  refreshReceipts(fromFilters: boolean): void {

    if (fromFilters) {
      this.longJobFilters = true;
    } else {
      this.longJobRefresh = true;
    }
    this.yubillyService
      .getReceiptsWithTotals(
        (this.selectedMerchant.merchantId === 0) ? "" : this.selectedMerchant.merchantName,
        this.selectedLabel.labelId,
        this.selectedPeriod.periodId,
        this.selectedCurrency.currencyCode
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          // this.receipts = data;
          // console.log(data[0]);
          this.receipts = data[0].receipts;
          this.totals = data[0].totals;
          if (fromFilters) {
            this.longJobFilters = false;
          } else {
            this.longJobRefresh = false;
          }
        },
        (error) => {
          if (fromFilters) {
            this.longJobFilters = false;
          } else {
            this.longJobRefresh = false;
          }
        }
      );
  }
  onPreviousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      this.lastPage = false;
    }
  }

  onNextPage(): void {
    if (!this.lastPage) {
      this.pageNumber = this.pageNumber + 1;
    }
  }

  getReceiptFile(receipt: Receipt): void {
    receipt.downloadingReceipt = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptFile(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.emailTime, '_yyyyMMdd_HHmmss') +
            '.pdf';
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingReceipt = false;
        },
        (error) => {
          receipt.downloadingReceipt = false;
        }
      );
  }

  getReceiptEmail(receipt: Receipt): void {
    receipt.downloadingEmail = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptEmail(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/eml' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') +
            '.eml';
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingEmail = false;
        },
        (error) => {
          receipt.downloadingEmail = false;
        }
      );
  }

  getReceiptAttachment(receipt: Receipt): void {
    receipt.downloadingReceipt = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptAttachmentUrl(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const url: string = res.preSignedUrl;
          // console.log('url:'+url);
          let fileType: string;
          let fileExtension: string;
          if(url.includes(".jpg?")){
            fileType = "image/jpeg";
            fileExtension = ".jpg";
          }else if(url.includes(".gif?")){
            fileType = "image/gif";
            fileExtension = ".gif";
          }else if(url.includes(".pdf?")){
            fileType = "application/pdf";
            fileExtension = ".pdf";
          }else if(url.includes(".html?")){
            fileType = "text/html";
            fileExtension = ".html";
          }

          this.yubillyService
          .getFileFromAWSS3(url)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (data) => {
              // console.log(data);
              const blob = new Blob([data], { type: fileType });
              const downloadURL = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = downloadURL;
              link.download =
                receipt.merchantName +
                this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') + fileExtension;
              link.click();
              this.downloadEnabled = true;
              receipt.downloadingReceipt = false;
            },
            (error) => {
              receipt.downloadingReceipt = false;
            }
          );
          this.downloadEnabled = true;
          receipt.downloadingReceipt = false;
        },
        (error) => {
          receipt.downloadingReceipt = false;
        }
      );
  }

  getReceiptFile2(receipt: Receipt): void {
    receipt.downloadingReceipt = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptFile(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {  // .jpg?
          console.log(data);
          const blob = new Blob([data], { type: 'application/pdf' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') +
            '.pdf';
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingReceipt = false;
        },
        (error) => {
          receipt.downloadingReceipt = false;
        }
      );
  }

  getReceiptEmail2(receipt: Receipt): void {
    receipt.downloadingEmail = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptEmail(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/eml' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') +
            '.eml';
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingEmail = false;
        },
        (error) => {
          receipt.downloadingEmail = false;
        }
      );
  }

  getReceiptItems(receipt: Receipt): void {
    if (receipt.showItems === true) {
      receipt.showItems = false;
    } else {
      receipt.showItems = true;
      if (receipt.items === undefined) {
        this.downloadingItems = true;
        this.yubillyService
          .getReceiptItems(receipt.receiptId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              receipt.items = res;
              this.downloadingItems = false;
              // console.log(receipt.items);
            },
            (error) => {
              this.downloadingItems = false;
            }
          );
      }
    }
  }

  getMerchantList(): void {
    this.downloadingLookupList = true;
    this.yubillyService
      .getMerchants()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.merchants = res;
          this.downloadingLookupList = false;
        },
        (error) => {
          this.downloadingLookupList = false;
        }
      );
  }

  onReceiptUpdate(receipt: Receipt): any {
    this.receiptIndexToEdit = this.receipts.indexOf(receipt);
    this.receiptToEdit = Object.assign({}, receipt);
    //
    history.pushState(null, null);
    this.isContainerListActive = false;
  }

  submitReceipt(): any {
    this.yubillyService
      .updateReceipt(this.receiptToEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.receipts[this.receiptIndexToEdit] = data;
        const id = data.merchantId;
        for (let i = 0; i < this.receipts.length; i++) {
          if (this.receipts[i].merchantId === id) {
            this.receipts[i].merchantName = data.merchantName;
          }
        }
        this.isContainerListActive = true;
        history.back();
      });
  }

  cancelEdit(): void {
    this.isContainerListActive = true;
    history.back();
  }

  onMerchantSelection(contentMerchantSelectionTemplate: string): void {
    if (this.merchants.length <= 0) {
      this.getMerchantList();
    }
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig
      .openV2(contentMerchantSelectionTemplate)
      .then((result: any) => {
        this.isModalSelectionActive = false;
      })
      .catch((reason: any) => {
        if (reason !== 'back') {
          // 'back' comes from browser's back that clears the history
          // we added just before calling the modal window
          // otherwise we need to clear that fake history as follows
          this.isModalSelectionActive = false;
          // history.back();
          // history.forward();
        }
      });
  }
  selectMerchant(merchant: Merchant): void {
    this.receiptToEdit.merchantId = merchant.merchantId;
    this.receiptToEdit.merchantName = merchant.merchantName;
    this.receiptToEdit.invoiceEmail = merchant.invoiceEmail;
  }

  onEditMerchantName(contentMerchantNameEditTemplate: string): void {

    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.merchantNameEdit = this.receiptToEdit.merchantName;
    this.modalConfig
      .openV2(contentMerchantNameEditTemplate)
      .then((result: any) => {
        this.isModalSelectionActive = false;
        if (this.merchantNameEdit.trim().length >= 2) {
          this.receiptToEdit.merchantName = this.merchantNameEdit;
        }
      })
      .catch((reason: any) => {
        if (reason !== 'back') {
          this.isModalSelectionActive = false;
        }
      });
  }

  onLabelSelection(contentLabelSelectionTemplate: string): void {
    if (this.labels.length <= 0) {
      this.downloadingLookupList = true;
      this.yubillyService
        .getLabels()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.labels = result;
            this.labels.push({
              labelId: 0,
              labelName: '',
              labelColor: '',
              inUse: false,
            });
            this.downloadingLookupList = false;
          },
          (error) => {
            this.downloadingLookupList = false;
          }
        );
    }
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig
      .openV2(contentLabelSelectionTemplate)
      .then(() => {
        this.isModalSelectionActive = false;
        //history.back();
      })
      .catch((reason) => {
        if (reason !== 'back') {
          this.isModalSelectionActive = false;
          //history.back();
        }
      });
  }

  selectLabel(label: Label): void {
    this.receiptToEdit.labelId = label.labelId;
    this.receiptToEdit.labelName = label.labelId === 0 ? '' : label.labelName;
    this.receiptToEdit.labelColor = label.labelColor;
  }

  onCurrencySelection(contentCurrencySelectionTemplate: string): void {
    if (this.myCurrencyCodes.length <= 0) {
      this.downloadingLookupList = true;
      this.yubillyService
        .getMyCurrencyCodes()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.myCurrencyCodes = result;
            this.downloadingLookupList = false;
          },
          (error) => {
            this.downloadingLookupList = false;
          }
        );
    }
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig
      .openV2(contentCurrencySelectionTemplate)
      .then(() => {
        //history.back();
        this.isModalSelectionActive = false;
      })
      .catch((reason) => {
        if (reason !== 'back') {
          //history.back();
          this.isModalSelectionActive = false;
        }
      });
  }

  selectCurrency(currency: Currency): void {
    this.receiptToEdit.currency = currency.currencyCode;
    this.receiptToEdit.currency =
      currency.currencyCode === 'allCurrencies' ? '' : currency.currencyCode;
  }

  exportData(content: string): void {
    // console.log('===Receipt List to Export===');
    // console.log(document.getElementById('pageTitle').innerText);
    const fileNameStartsWith = document.getElementById('pageTitle').innerText.trim();
    this.fileName = fileNameStartsWith
      + this.datePipe.transform(new Date(), '_yyyyMMdd_HHmmss');
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      if (this.fileName === '') {
        this.fileName = fileNameStartsWith
          + this.datePipe.transform(new Date(), '_yyyyMMdd_HHmmss');
      }
      const wb = new Workbook();
      const ws = wb.addWorksheet(fileNameStartsWith.toString());
      // console.log(this.translate.parser('merchant'));
      this.translate.get(['date', 'merchant', 'label', 'total', 'currency']).subscribe((res) => {
        // console.log(res);
        ws.columns = [
          { header: res.date, key: 'invoiceDate', width: 15 },
          { header: res.merchant, key: 'merchantName', width: 30 },
          { header: res.label, key: 'labelName', width: 12 },
          { header: res.total, key: 'total', width: 12 },
          { header: res.currency, key: 'currency', width: 12 },
        ];
        // ws.addRows(this.receipts, 'n');
        this.receipts.forEach(e => {
          ws.addRow({
            invoiceDate: new Date(e.invoiceDate),
            merchantName: e.merchantName,
            labelName: e.labelName,
            total: Number(e.total) === 0 ? '' : Number(e.total),
            currency: e.currency
          }, 'n');
        });
        ws.getColumn('invoiceDate').alignment = { horizontal: 'left' };
        ws.getColumn('total').alignment = { horizontal: 'right' };
        ws.getColumn('currency').alignment = { horizontal: 'center' };
        ws.getColumn('total').numFmt = '#,##0.00;[Red]\-#,##0.00';

        wb.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // { type: 'text/csv' }
          fs.saveAs(blob, (this.fileName + '.xlsx'));
        });
        this.isModalSelectionActive = false;
      },
        (error) => {
          console.log(error);
          this.isModalSelectionActive = false;
        }
      );
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });
  }

  moveToRecycleBin(receiptId: number): void {
    // Are you sure?
    /*history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      this.isModalSelectionActive = false;
      this.yubillyService.removeLabel(labelId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.labels = data;
        });
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });*/

    this.longJobRefresh = true;
    this.yubillyService
      .moveReceiptToRecycleBin(receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.receipts.splice(this.receiptIndexToEdit, 1);
          this.longJobRefresh = false;
          this.isContainerListActive = true;
          history.back();
        },
        (error) => {
          this.longJobRefresh = false;
        }
      );
  }

  hideSpinner(): void {
    document.getElementById('spinner').style.display = 'none';
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
