import { Injectable, OnInit } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/components/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor {

  /*To preserve a custom header and
    add the Authorization header as well, used :
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
    instead of :
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  */

  constructor(
    private authService: AuthService,
    private translate: TranslateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('nameRecovery') || req.url.includes('signUp')) {
      return next.handle(req);
    } else {
      if (req.url.includes('yubilly')) {
        return from(this.authService.getAccessToken()
          .then(token => {
            // console.log(token);
            var headers = req.headers
              .set('Authorization', `Bearer ${token}`)
              /*.set('Accept-Language', this.translate.currentLang)*/;
            let params = req.params;
            if (
              !req.url.includes('offer.yubilly.com')
            && !req.url.includes('receipt.yubilly.com')
            && !req.url.includes('upload.yubilly.com')) {
              params = req.params.append('language', this.translate.currentLang);
            }
            /*if (req.url.includes('offer.yubilly.com')
            || req.url.includes('upload.yubilly.com')) {
              headers = headers.set('x-amz-acl', 'bucket-owner-full-control');
            }*/
            const authRequest = req.clone({url: req.url.replace('http://', 'https://')})
            .clone({ headers }).clone({ params });
            // console.log(authRequest);
            return next.handle(authRequest).toPromise();
          }));
      } else if (req.url.includes('localhost')) {
        return from(this.authService.getAccessToken()
          .then(token => {
            const headers = req.headers
              .set('Authorization', `Bearer ${token}`);
            let params = req.params;
            if (!req.url.includes('offer.yubilly.com')) {
              params = req.params.append('language', this.translate.currentLang);
            }
            const authRequest = req.clone({ url: req.url }).clone({ headers }).clone({ params });
            return next.handle(authRequest).toPromise();
          }));
      } else {
        return next.handle(req);
      }
    }
  }
}
